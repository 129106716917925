import React, { useMemo } from "react";
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputLeftAddon,
} from "@chakra-ui/react";
import { Control, FieldErrors, FormState } from "react-hook-form";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import CountryCodeAsyncSelect from "@components/form/country-code-async-select/country-code-async-select";
import DirtyFieldHelperText from "@components/form/dirty-field-helper-text/dirty-field-helper-text";

interface FormInputProps {
	name: string;
	keys?: { code?: string; number?: string };
	formRegister: UseFormRegister<any>;
	control: Control<any, any>;
	formState: FormState<any>;
	label?: string;
	disabled?: boolean;
	placeholder?: string;
	shouldShowUnsavedWarning?: boolean;
	required?: boolean;
}

const FormTelInput: React.FC<FormInputProps> = ({
	label,
	name = "telephone",
	formRegister,
	disabled,
	control,
	placeholder,
	keys = { code: "code", number: "number" },
	formState,
	shouldShowUnsavedWarning,
	required
}) => {
	const { number: numberKey, code: codeKey } = keys;

	const { errors = {}, dirtyFields = {} } = useMemo(() => {
		return formState || {};
	}, [formState]);

	const numberName = `${name}.${numberKey}`;
	const codeName = `${name}.${codeKey}`;

	return (
		<FormControl isInvalid={!!errors[name]}>
			{!!label && (
				<FormLabel htmlFor={name}>
					<Heading size={"sm"}>{label}
						{required && <span style={{ marginLeft: 5, color: "red" }}>*</span>}
					</Heading>
				</FormLabel>
			)}
			<InputGroup>
				<InputLeftAddon p={0}>
					<CountryCodeAsyncSelect name={codeName} control={control} />
				</InputLeftAddon>
				<Input
					autoComplete={"tel"}
					disabled={disabled}
					id={numberName}
					placeholder={placeholder}
					required={required}
					{...formRegister(numberName)}
				/>
			</InputGroup>
			{shouldShowUnsavedWarning && dirtyFields[name] && (
				<DirtyFieldHelperText />
			)}
			<FormErrorMessage>{errors[name]?.[0]}</FormErrorMessage>
		</FormControl>
	);
};

export default FormTelInput;
