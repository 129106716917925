import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import FormInput from "@components/form/form-input/form-input";
import { Button } from "@components";
import { IUser, IUserFormData, UserTypeEnum } from "@interfaces/api/auth.types";
import { userStatuses } from "@definitions/user-types";
import {
	ButtonProps,
	Center,
	Checkbox,
	SimpleGrid,
	Stack,
	Text,
	useColorModeValue,
	useToast,
	VStack,
} from "@chakra-ui/react";
import FormAsyncSelect from "@components/form/form-async-select/form-async-select";
import {
	getUserRoles,
	getUsers,
	resetPassword,
	sendGDPRConsent,
} from "@api/auth.api";
import { brandColor } from "@definitions/chakra/theme/foundations/colors";
import SectionHeader from "@components/section-header/section-header";
import FormSelect from "@components/form/form-select/form-select";
import selectKeys, { SelectValueTypes } from "@definitions/select-keys";
import { deleteCity, getCities, getCountries } from "@api/geocoding.api";
import numberify from "@utils/numberify";
import convertDataToSelectOption from "@utils/convert-data-to-select-option";
import FormTelInput from "@components/form/form-tel-input/form-tel-input";
import { toTelephone, toTelephoneInput } from "@utils/formatters";
import FormWysiwygInput from "@components/form/form-wysiwyg-input/form-wysiwyg-input";
import useFormDraft from "@hooks/useFormDraft";
import { LSUserFormDraft } from "@definitions/local-storage.keys";
import { useOnLeavePageConfirmation } from "@hooks/useOnLeavePageConfirm";

const statuses = userStatuses.map((type) => ({
	label: type.statusName,
	value: type.status,
	data: type,
}));

export interface UserFormProps {
	onSubmit: (values: Partial<IUserFormData>) => void;
	initData?: Partial<IUser>;
	viewerType?: UserTypeEnum;
	isMe?: boolean;
	submitButtonLabel?: string;
	withPassword?: boolean;
	withRoles?: boolean;
	withNotes?: boolean;
	submitButtonProps?: ButtonProps;
	shouldShowUnsavedWarnings?: boolean;
	fieldsRequired?: boolean;
}

const UserForm: React.FC<UserFormProps> = ({
	onSubmit: onFormSubmit,
	initData,
	viewerType,
	isMe,
	withRoles,
	withPassword,
	withNotes,
	submitButtonLabel,
	submitButtonProps = {},
	shouldShowUnsavedWarnings = true,
	children,
	fieldsRequired,
}) => {
	const {
		role = {},
		login = "",
		firstName = "",
		lastName = "",
		status = {},
		id: userId,
		industry,
		desk,
		employer,
		telephone,
		tags,
		country,
		city,
		workCountry,
		workCity,
		consultant,
		jobTitle,
		jobTitleSelect,
		languages,
		notes,
	} = useMemo(() => initData || {}, [initData]);

	const {
		register,
		control,
		handleSubmit,
		resetField,
		formState: { isDirty, isSubmitting },
		formState,
		watch,
		setError,
		clearErrors,
	} = useFormDraft<IUserFormData>({
		key: LSUserFormDraft(userId || "new"),
		defaultValues: {
			roleSelect: {
				...role,
			},
			statusSelect: {
				...status,
			},
			login,
			firstName,
			lastName,
			industrySelect: industry,
			deskSelect: desk,
			activitySelect: industry?.activity,
			employerSelect: employer,
			telephone,
			workCountrySelect: workCountry,
			workCity,
			jobTitle,
			jobTitleSelect,
			telephoneInput: toTelephoneInput(telephone),
			countrySelect: country,
			city,
			languagesSelect: languages,
			notes,
			consultantSelect: convertDataToSelectOption({
				labelKey: ["firstName", "lastName"],
				valueKey: "id",
				originalData: consultant || undefined,
			}),
		},
	});

	useOnLeavePageConfirmation(isDirty);

	const watchIndustry = watch("industrySelect");
	const watchRole = watch("roleSelect");
	const watchCountry = watch("countrySelect");
	const watchWorkCountry = watch("workCountrySelect");

	const activities = useMemo(() => {
		resetField("activitySelect", undefined);
		return selectKeys.activity(watchIndustry?.value) as SelectValueTypes;
	}, [JSON.stringify(watchIndustry)]);

	const isCandidate = useMemo(
		() => initData?.role?.value === UserTypeEnum.Candidate,
		[userId],
	);

	const isEditing = useMemo(() => !!initData?.id, [userId]);
	const buttonLabel = useMemo(() => {
		if (submitButtonLabel) return submitButtonLabel;
		if (isEditing) {
			return isMe ? "Update my profile" : "Update user";
		}
		return viewerType === UserTypeEnum.Admin ? "Create user" : "Submit data";
	}, [userId]);

	const canCreateSelectOptions = useMemo(() => {
		return viewerType === UserTypeEnum.Admin;
	}, [viewerType]);

	const onSubmit = async (values: IUserFormData) => {
		clearErrors();
		const data: Partial<IUserFormData> = {
			...values,
			roleId: numberify(values.roleSelect?.value),
			country: values.countrySelect,
			workCountry: values.workCountrySelect,
			jobTitleSelectId: numberify(values.jobTitleSelect?.value),
			industryId: numberify(values.industrySelect?.value),
			deskId: numberify(values.deskSelect?.value),
			activityId: numberify(values.activitySelect?.value),
			employerId: numberify(values.employerSelect?.value),
			consultantId: numberify(values.consultantSelect?.value),
			telephone: toTelephone(values.telephoneInput),
			languageIds:
				values.languagesSelect?.map((language) => Number(language.value)) || [],
			tags: tags?.map((tag) => tag.label) || [],
		};
		console.log(data);
		delete data.countrySelect;
		delete data.workCountrySelect;
		delete data.jobTitleSelect;
		delete data.industrySelect;
		delete data.activitySelect;
		delete data.deskSelect;
		delete data.roleSelect;
		delete data.employerSelect;
		delete data.consultantSelect;
		delete data.telephoneInput;
		delete data.languagesSelect;
		console.log(data);
		try {
			await onFormSubmit(data);
		} catch (e: any) {
			const { data } = e || {};
			Object.keys(data?.viewBag)?.forEach((key) =>
				setError(key as keyof IUserFormData, data?.viewBag[key]),
			);
			console.log(e);
		}
	};

	const ActivitySelect = useCallback(() => {
		return watchIndustry?.value ? (
			<FormAsyncSelect
				name={"activitySelect"}
				label={"Activity"}
				creatable={canCreateSelectOptions}
				control={control}
				selector={activities}
				shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
			/>
		) : null;
	}, [JSON.stringify(activities)]);

	const buttonBg = useColorModeValue(brandColor[0], brandColor[1]);
	const textColor = useColorModeValue("white", "white");
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				clearErrors();
				handleSubmit(onSubmit)(e);
			}}
			onKeyDown={(e) => {
				if (e.code === "Enter") e.preventDefault();
			}}
		>
			{viewerType === UserTypeEnum.Admin && isCandidate && (
				<>
					<SectionHeader label="Consultant" />
					<SimpleGrid spacing={4} columns={{ base: 1, lg: 2 }}>
						<FormAsyncSelect
							shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
							name={"consultantSelect"}
							label={"Consultant"}
							control={control}
							selector={() => getUsers({ filters: { roles: "1,2" } })}
							mappingKeys={{
								labelKey: ["firstName", "lastName"],
								valueKey: "id",
							}}
						/>
					</SimpleGrid>
				</>
			)}
			<SectionHeader label="Basic info" />
			<SimpleGrid spacing={4} columns={{ base: 1, lg: 2 }}>
				{viewerType === UserTypeEnum.Admin && (
					<FormSelect
						name={"statusSelect"}
						label={"Status"}
						control={control}
						options={statuses}
						shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
					/>
				)}
				{withRoles && viewerType === UserTypeEnum.Admin && (
					<FormAsyncSelect
						name={"roleSelect"}
						label={"Role"}
						control={control}
						selector={getUserRoles}
						mappingKeys={{ labelKey: "label", valueKey: "value" }}
						disabled={viewerType !== UserTypeEnum.Admin}
						shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
					/>
				)}
				{withPassword && (
					<>
						<FormInput
							shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
							name={"password"}
							label={"Password"}
							formState={formState}
							formRegister={register}
							required={fieldsRequired}
						/>
						<FormInput
							shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
							name={"confirmPassword"}
							label={"Repeat password"}
							formState={formState}
							formRegister={register}
							required={fieldsRequired}
						/>
					</>
				)}
				<FormInput
					shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
					name={"firstName"}
					label={"First name"}
					formState={formState}
					formRegister={register}
					required={fieldsRequired}
				/>
				<FormInput
					shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
					name={"lastName"}
					label={"Last name"}
					formState={formState}
					formRegister={register}
					required={fieldsRequired}
				/>
			</SimpleGrid>
			<SectionHeader label="Contact info" wrapperProps={{ mt: 6 }} />
			<SimpleGrid spacing={4} columns={{ base: 1, lg: 2 }}>
				<FormInput
					shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
					name={"login"}
					label={"Email"}
					formState={formState}
					formRegister={register}
					required={fieldsRequired}
				/>
				<FormTelInput
					name={"telephoneInput"}
					label={"Telephone"}
					formState={formState}
					formRegister={register}
					control={control}
					required={fieldsRequired}
				/>
				<FormAsyncSelect
					name={"countrySelect"}
					label={"Country"}
					control={control}
					selector={getCountries}
					mappingKeys={{
						labelKey: ["flag", "name.common"],
						valueKey: ["name.common"],
					}}
					shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
					required={fieldsRequired}
				/>
				{!!watchCountry?.value && (
					<FormAsyncSelect
						name={"city"}
						label={"City"}
						control={control}
						selectorFilters={{ Country: watchCountry?.value }}
						selector={getCities}
						mappingKeys={{
							labelKey: "label",
							valueKey: "value",
						}}
						localCreatable={canCreateSelectOptions}
						onOptionDelete={deleteCity}
						shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
					/>
				)}
			</SimpleGrid>
			{(isCandidate ||
				!viewerType ||
				watchRole?.value === UserTypeEnum.Candidate ||
				!watchRole?.value) && (
				<>
					<SectionHeader label="Career" wrapperProps={{ mt: 6 }} />
					<SimpleGrid spacing={4} columns={{ base: 1, lg: 2 }}>
						<FormAsyncSelect
							name={"languagesSelect"}
							label={"Spoken languages"}
							creatable={canCreateSelectOptions}
							isMulti
							control={control}
							selector={selectKeys.language}
							shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
							required={fieldsRequired}
						/>
						<FormAsyncSelect
							name={"industrySelect"}
							label={"Industry"}
							creatable={canCreateSelectOptions}
							control={control}
							selector={selectKeys.industry}
							shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
							required={fieldsRequired}
						/>
						<FormAsyncSelect
							name={"deskSelect"}
							label={"Desk"}
							creatable={canCreateSelectOptions}
							control={control}
							selector={selectKeys.desk}
							shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
							required={fieldsRequired}
						/>
						<ActivitySelect />
						<FormInput
							shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
							required={fieldsRequired}
							name={"jobTitle"}
							label={"Job title"}
							formState={formState}
							formRegister={register}
						/>
						<VStack>
							<FormAsyncSelect
								name={"employerSelect"}
								label={"Employer"}
								creatable={canCreateSelectOptions}
								control={control}
								selector={selectKeys.employer}
								shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
								required={fieldsRequired}
							/>
						</VStack>

						<FormAsyncSelect
							name={"workCountrySelect"}
							label={"Work country"}
							control={control}
							selector={getCountries}
							mappingKeys={{
								labelKey: ["flag", "name.common"],
								valueKey: ["name.common"],
							}}
							shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
							required={fieldsRequired}
						/>
						{!!watchWorkCountry?.value && (
							<FormAsyncSelect
								name={"workCity"}
								label={"Work city"}
								control={control}
								selector={getCities}
								selectorFilters={{ Country: watchWorkCountry?.value }}
								mappingKeys={{
									labelKey: "label",
									valueKey: "value",
								}}
								localCreatable={canCreateSelectOptions}
								onOptionDelete={deleteCity}
								shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
							/>
						)}
					</SimpleGrid>
				</>
			)}
			{withNotes &&
				(viewerType === UserTypeEnum.Admin ||
					viewerType === UserTypeEnum.Consultant) && (
					<>
						<SectionHeader label="Notes" wrapperProps={{ mt: 6 }} />
						<FormWysiwygInput
							name={"notes"}
							control={control}
							shouldShowUnsavedWarning={shouldShowUnsavedWarnings}
						/>
					</>
				)}
			{children}
			<Center my={4}>
				<Button
					type="submit"
					bg={buttonBg}
					color={textColor}
					isLoading={isSubmitting}
					{...submitButtonProps}
				>
					{buttonLabel}
				</Button>
			</Center>
		</form>
	);
};

export default UserForm;
