const userTypes = [
	{
		role: 0,
		roleName: "Undefined",
	},
	{
		role: 1,
		roleName: "Consultant",
	},
	{
		role: 2,
		roleName: "Admin",
	},
	{
		role: 3,
		roleName: "Candidate",
	},
];

export const userStatuses = [
	{
		status: 0,
		statusName: "NotVerified",
	},
	{
		status: 1,
		statusName: "Verified",
	},
];

export default userTypes;
