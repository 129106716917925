import React, { FC, useCallback } from "react";
import { AsyncSelect } from "chakra-react-select";
//@ts-ignore
import { components } from "react-select";

import {
	Box,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
} from "@chakra-ui/react";
import { getCountries } from "@api/geocoding.api";
import { ICountry } from "@interfaces/api/geocoding.types";
import debounce from "@utils/debounce";
import { Control, Controller } from "react-hook-form";

export interface IOption {
	label: string;
	value: string | number;
	data?: any;
}

interface FormAsyncSelectProps {
	name: string;
	control: Control<any, any>;
	placeholder?: string;
	disabled?: boolean;
	isMutli?: boolean;
	label?: string;
	value?: any;
}

const CountryCodeAsyncSelect: FC<FormAsyncSelectProps> = ({
	name,
	control,
	disabled,
	label,
}) => {
	const mapOptions = (options: ICountry[], inputValue?: string) => {
		const items: IOption[] = [];
		options
			.map((option) => {
				const isMatchByName = option.name?.common
					?.toLowerCase()
					.includes(inputValue?.toLowerCase() as string);
				const isMatchByCode = option.idd?.suffixes?.some((suffix) =>
					(option.idd?.root + suffix).includes(
						inputValue?.toLowerCase() as string,
					),
				);
				if (isMatchByCode) {
					return {
						...option,
						idd: {
							...option.idd,
							suffixes: option.idd.suffixes.filter(
								(
									suffix, //filter only country suffixes that are matching with input
								) =>
									(option.idd?.root + suffix).includes(
										inputValue?.toLowerCase() as string,
									),
							),
						},
					};
				}
				if (isMatchByName) return option;
			})
			.forEach((option) =>
				option?.idd?.suffixes?.map((suffix) => {
					items.push(
						{
							label: `${option.flag} ${option.idd.root}${suffix}`,
							value: `${option.idd.root}${suffix}`,
							data: option,
						} || {
							label: `${option.flag} ${option?.idd.root}`,
							value: `${option?.idd.root}`,
							data: option,
						},
					);
				}),
			);
		console.log(items);
		return items;
	};

	const fetchOptions = async (inputValue: string) => {
		try {
			const response = await getCountries(inputValue);
			return mapOptions(response.items, inputValue);
		} catch (e) {
			console.warn(e);
			return [];
		}
	};

	const loadOptionsDebounced = useCallback(
		debounce((inputValue: string, callback: (options: any) => void) => {
			fetchOptions(inputValue).then((options) => callback(options));
		}, 500),
		[],
	);

	return (
		<Controller
			control={control}
			name={name}
			render={({
				field: { onChange, onBlur, value, name, ref },
				fieldState: { invalid, error },
			}) => (
				<FormControl isInvalid={invalid}>
					<Box minW={"150px"}>
						{!!label && (
							<FormLabel htmlFor={name}>
								<Heading size={"sm"}>{label}</Heading>
							</FormLabel>
						)}
						<AsyncSelect
							ref={ref}
							id={name}
							name={name}
							placeholder={"Code"}
							defaultOptions
							onChange={onChange}
							onBlur={onBlur}
							isDisabled={disabled}
							value={value}
							loadOptions={loadOptionsDebounced}
							components={{
								Input: (props) => {
									return <components.Input {...props} autoComplete={"none"} />;
								},
							}}
						/>
						{!!error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
					</Box>
				</FormControl>
			)}
		/>
	);
};

export default CountryCodeAsyncSelect;
